var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AuthLayout',[_c('div',{staticClass:"auth-header"},[_c('LogoSequoia')],1),_c('div',{staticClass:"auth-content"},[_c('AuthSlider'),_c('div',{staticClass:"auth-forms"},[_c('div',{staticClass:"auth-forms-inner"},[(_vm.currentStep === _vm.steps.promoCode)?_c('StepPromoCode',{attrs:{"code":_vm.code,"title":_vm.getTranslation(_vm.code ? 'auth_promo_code_page_title' : 'auth_promo_code_title'),"description":_vm.getTranslation(
              _vm.code ? 'auth_promo_code_page_description' : 'auth_promo_code_description'
            ),"button-text":_vm.getTranslation(_vm.code ? 'signup' : 'auth_promo_code_btn')},on:{"changeStep":function($event){_vm.currentStep = _vm.steps.promoCodeNotFound}}}):_vm._e(),(_vm.currentStep === _vm.steps.promoCodeNotFound)?_c('StepOther',{attrs:{"title":_vm.getTranslation('auth_promo_code_error_title'),"button-text":_vm.getTranslation('enter_another'),"button-text2":_vm.getTranslation('auth_promo_code_next_without_code'),"error":true,"notify-text":_vm.getTranslation('auth_promo_code_not_code'),"icon-name":"IconCrossCircled","icon-class-name":"color-error"},on:{"clickButton":_vm.setOtherCode,"clickButton2":function($event){return _vm.$router.push(_vm.isSmotreshka || _vm.isN3 ? '/reg' : '/login')}},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.getTranslation('auth_promo_code_notFound_description').replace(
                  '%code%',
                  _vm.$store.authAndReg.promoCode.toUpperCase()
                )
              )}})]},proxy:true}],null,false,4281785531)}):_vm._e()],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }