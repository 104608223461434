<template>
  <AuthLayout>
    <div class="auth-header">
      <LogoSequoia />
    </div>
    <div class="auth-content">
      <AuthSlider />
      <div class="auth-forms">
        <div class="auth-forms-inner">
          <StepPromoCode
            v-if="currentStep === steps.promoCode"
            :code="code"
            :title="getTranslation(code ? 'auth_promo_code_page_title' : 'auth_promo_code_title')"
            :description="
              getTranslation(
                code ? 'auth_promo_code_page_description' : 'auth_promo_code_description'
              )
            "
            :button-text="getTranslation(code ? 'signup' : 'auth_promo_code_btn')"
            @changeStep="currentStep = steps.promoCodeNotFound"
          />
          <StepOther
            v-if="currentStep === steps.promoCodeNotFound"
            :title="getTranslation('auth_promo_code_error_title')"
            :button-text="getTranslation('enter_another')"
            :button-text2="getTranslation('auth_promo_code_next_without_code')"
            :error="true"
            :notify-text="getTranslation('auth_promo_code_not_code')"
            icon-name="IconCrossCircled"
            icon-class-name="color-error"
            @clickButton="setOtherCode"
            @clickButton2="$router.push(isSmotreshka || isN3 ? '/reg' : '/login')"
          >
            <template v-slot:description>
              <span
                v-html="
                  getTranslation('auth_promo_code_notFound_description').replace(
                    '%code%',
                    $store.authAndReg.promoCode.toUpperCase()
                  )
                "
              />
            </template>
          </StepOther>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaPage } from 'src/mixins';
import LogoSequoia from 'src/components/auth/parts/LogoSequoia.vue';
import AuthLayout from 'src/layouts/AuthLayout.vue';
import AuthSlider from 'src/components/auth/parts/AuthSlider.vue';
import StepPromoCode from 'src/components/auth/steps/StepPromoCode.vue';
import StepOther from 'src/components/auth/steps/StepOther.vue';
import { AUTH_STEPS } from 'src/constants';
import { wlDetector } from 'src/utils';

@Component({
  components: { AuthLayout, LogoSequoia, AuthSlider, StepPromoCode, StepOther },
})
export default class CodePage extends SequoiaPage {
  currentStep = AUTH_STEPS.promoCode;
  steps = AUTH_STEPS;

  get isSmotreshka() {
    return wlDetector().isSmotreshka;
  }

  get isN3() {
    return wlDetector().isN3;
  }

  get code() {
    return (this.$route.query.promocode as string) || '';
  }

  setOtherCode() {
    this.currentStep = AUTH_STEPS.promoCode;
    this.$router.push('/code');
  }
}
</script>
